@import 'settings/variable';  //themes' colors

//base...
@import 'generic/normalize.scss';
@import 'generic/box-sizing.scss';
@import './base/scaffolding';         // styles of base elements
@import './base/typography';          // base styles of h1-h6, p, span

//components...
@import 'component/btn';
@import './component/card';
@import './component/check-box';
@import './component/sidebar';
@import './component/topbar';
@import './component/load';
@import './component/form';
@import './component/material-form';
@import './component/panel.scss';
@import './component/pagination.scss';
@import './component/edit-table.scss';
@import './component/select-options.scss';
@import './component/table.scss';
@import './component/catalog-items.scss';
@import './component/notification.scss';
@import './component/confirmation.scss';
@import './component/collapse.scss';
@import './component/dropzone.scss';
//@import './component/date-picker.scss';
@import './component/phoneinput.scss';
@import './component/react-tag.scss';

@import './component/checkout-list.scss';

//pages...
@import 'containers/account';
@import 'containers/dashboard';

//objects...
@import 'objects/layout.scss';

.clickable {
    cursor: pointer;
}

.logo {
    width: 160px;
    height: 160px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    margin-bottom: 30px;
}

.center {
    text-align: center;
}

.right {
    float: right;
}

.test {
    background-color: black;
}

.dropzone-imgsrc {
    max-height: 300px;
    max-width: 300px;
    object-fit: cover;
}
