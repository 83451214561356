.checkoutlist__collapse-item {
    padding: 12px 55px 12px 70px;
    display: flex;
    position: relative;
    height: 62px;
    align-items: center;
    flex-wrap: wrap;
  
    @include themify($themes) {
      border-bottom: 1px solid themed('colorBorder');
    }
  }

  .checkoutlist__collapse-name {
    margin: 0;
    font-size: 16px;
    line-height: 16px;
    color: $color-accent;
  }
  
  .checkoutlist__collapse-date {
    position: absolute;
    right: 15px;
    font-size: 14px;
    color: $color-gray;
    margin-top: 2px;
  }

  .checkoutlist__collapse-desc {
    margin: 0;
    font-size: 12px;
    line-height: 16px;
    color: $color-accent;
  }